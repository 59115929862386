import classNames from "classnames";

type PaymentFrequencyOptionProps = {
  text: string;
  isSelected: boolean;
  onClick(): void;
};

const PaymentFrequencyOption = ({
  text,
  isSelected,
  onClick,
}: PaymentFrequencyOptionProps) => {
  return (
    <div
      className={classNames(
        "flex-1 font-medium text-center py-4 bg-white/[.1] text-white rounded-lg border-2 shadow-[0_1px_6px_rgba(0,0,0,0.04)] cursor-pointer",
        {
          "border-primary-bright": isSelected,
          "border-white/[.2]": !isSelected,
        }
      )}
      onClick={() => onClick()}
      onKeyDown={() => onClick()}
      role="button"
      tabIndex={0}
    >
      {text}
    </div>
  );
};

export default PaymentFrequencyOption;
