function getValue(object: Record<string, unknown>, query: string) {
  const keys = query.split(".");

  return (
    keys.reduce(
      (obj, key) => obj && (obj[key] as Record<string, unknown>),
      object
    ) || undefined
  );
}

export default getValue;
