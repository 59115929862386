import { FieldErrors, Ref } from "react-hook-form";
import getValue from "./getValue";

function onSubmitError(focusOrder: string[]) {
  return (errors: FieldErrors) => {
    /**
     * TODO:
     * remove this function, hard-coded focus order, and { shouldFocusError: false } when react-hook-form comes up with solution for this issue
     *
     * in react-hook-form, the focus order depends on the register order
     * because we are using both Controller and native input register, we can't guarantee focus order
     * so we need to hardcode the focus priority and call focus() manually
     *
     * GitHub issues:
     * https://github.com/react-hook-form/react-hook-form/issues/10650 most recent (Jul 8, 2023)
     * https://github.com/react-hook-form/react-hook-form/issues/8398
     */
    const firstError = focusOrder.find(
      (value) => getValue(errors, value) !== undefined
    );

    if (firstError) {
      const fieldRef = getValue(errors, firstError)?.ref as Ref;

      if (fieldRef && fieldRef.focus) {
        fieldRef.focus();
      }
    }
  };
}

export default onSubmitError;
