import classNames from "classnames";

type LineItemProps = {
  id?: string;
  name: string;
  value: string;
  boldValue?: boolean;
  valueProps?: {
    classNames?: string;
  };
};

const LineItem = ({
  id,
  name,
  value,
  boldValue = false,
  valueProps,
}: LineItemProps) => {
  return (
    <div id={id} className="flex justify-between items-baseline py-1.5">
      <div className="text-sm text-gray-500 font-medium uppercase">{name}</div>
      <div
        className={classNames(
          `text-base whitespace-nowrap`,
          valueProps?.classNames,
          {
            "text-primary-bold font-semibold": boldValue,
            "text-primary-deep font-medium": !boldValue,
          }
        )}
      >
        {value}
      </div>
    </div>
  );
};

export default LineItem;
