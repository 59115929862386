import { ReactNode } from "react";

type LineItemContainerProps = {
  title: string;
  children: ReactNode;
};

const LineItemContainer = ({ title, children }: LineItemContainerProps) => {
  return (
    <div>
      <h6 className="text-lg leading-6 font-semibold text-primary-deep">
        {title}
      </h6>
      <div className="px-1 pt-1">
        <div className="divide-y divide-gray-50 border-b border-b-gray-50">
          {children}
        </div>
      </div>
    </div>
  );
};

export default LineItemContainer;
