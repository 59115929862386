type DotButtonProps = {
  selected: boolean;
  onClick(): void;
  buttonColours?: "primary" | "secondary";
};

const DotButton = ({
  selected,
  onClick,
  buttonColours = "primary",
}: DotButtonProps) => {
  let selectedStyle = "";
  let disabledStyle = "";

  switch (buttonColours) {
    case "primary":
      selectedStyle = "bg-white";
      disabledStyle = "bg-[rgba(237,239,242)]/50";
      break;
    case "secondary":
      selectedStyle = "bg-gray-600";
      disabledStyle = "bg-black/25";
      break;
    default:
      throw Error("Style not supported");
  }

  const bgColour = selected ? selectedStyle : disabledStyle;

  return (
    <button
      aria-label="Gallery"
      className={`w-3 h-3 rounded-full ${bgColour}`}
      type="button"
      onClick={onClick}
    />
  );
};

export default DotButton;
