import { formatVehicleString, gtmPushData } from "@common/helpers/gtm";
import Button from "@common/components/Button";
import Label from "@common/components/form/Label";
import Listbox from "@common/components/form/Listbox";
import MoneyInput from "@common/components/form/MoneyInput";
import { ArrowRightIcon, InfoCircleIcon } from "@common/components/icons";
import {
  formatCurrency,
  formatPercent,
} from "@common/helpers/numberFormatting";
import translate from "@common/helpers/translate";
import useDebounce from "@common/hooks/useDebounce";
import { MAX_DECIMALS_TAX_BREAKDOWN } from "@common/constants";
import useVehiclePricingBreakdown from "@modules/vehicle/hooks/useVehiclePricingBreakdown";
import {
  PaymentFrequencies,
  PaymentFrequency,
} from "@modules/vehicle/types/PaymentFrequency";
import { VehicleDetail } from "@modules/vehicle/types/VehicleDetail";
import classNames from "classnames";
import { useState } from "react";
import { VehiclePricing } from "@modules/vehicle/types/DynamicVehicleDataResponse";
import { TFunction, Trans, useTranslation } from "next-i18next";
import canShowPreviousPrice from "@modules/vehicle/helpers/canShowPreviousPrice";
import LineItem from "./LineItem";
import LineItemContainer from "./LineItemContainer";
import PaymentFrequencyOption from "./PaymentFrequencyOption";

type PurchaseOptionsSection = "financing" | "cash";

export type LoanTerm = {
  id: string;
  months: number;
  name: string;
};

export const formatWithSymbol = (value: number, formattedValue: string) => {
  const symbol = value > 0 ? "\u2013" : "+";
  return `${symbol} ${formattedValue}`;
};

type Props = {
  vehicle: VehicleDetail;
  vehiclePricing: VehiclePricing;
  locale: string;
  onApplyForFinancingClick(): void;
  onNextStepsCashDownClick(): void;
  onAddTradeInClick(): void;
  triggerPriceBreakdownUpdate: boolean;
};

export const getLoanTerms = (t: TFunction): LoanTerm[] => {
  const terms: LoanTerm[] = [];

  for (let i = 1; i <= 8; i += 1) {
    const months = i * 12;
    const name =
      i === 1
        ? t("loan_term_singular", { ns: "vehicleDisplayPage" })
        : t("loan_term_plural", {
            ns: "vehicleDisplayPage",
            months,
            years: i,
          });

    terms.push({ id: i.toString(), months, name });
  }

  return terms;
};

const PreviousListingPriceLineItem = ({
  name,
  value,
}: {
  name: string;
  value: string;
}) => {
  return (
    <LineItem
      name={name}
      value={value}
      valueProps={{ classNames: "line-through text-gray-500 body-3" }}
    />
  );
};

const PurchaseOptions = ({
  vehicle,
  vehiclePricing,
  locale,
  onApplyForFinancingClick,
  onNextStepsCashDownClick,
  onAddTradeInClick,
  triggerPriceBreakdownUpdate,
}: Props) => {
  const { t } = useTranslation(["common", "vehicleDisplayPage", "purchase"]);
  const [loanTerms] = useState<LoanTerm[]>(getLoanTerms(t));

  const [purchaseOptionSection, setPurchaseOptionSection] =
    useState<PurchaseOptionsSection>("financing");

  const [tradeInAmount, setTradeInAmount] = useState(
    Math.round(vehiclePricing.tradeInValue)
  );
  const [cashDown, setCashDown] = useState(Math.round(vehiclePricing.cashDown));
  const [paymentFrequency, setPaymentFrequency] = useState<PaymentFrequency>(
    () =>
      Object.values(PaymentFrequencies).find(
        (pf) => pf.id === vehiclePricing.paymentFrequency.id
      ) || PaymentFrequencies.biWeekly
  );
  const [loanTerm, setLoanTerm] = useState(
    () =>
      loanTerms.find((lt) => lt.months === vehiclePricing.term) ?? loanTerms[0]
  );

  const debouncedTradeIn = useDebounce(tradeInAmount);
  const debouncedCashDown = useDebounce(cashDown);

  const { pricingBreakdown, refetch } = useVehiclePricingBreakdown(
    vehicle.stockNumber,
    paymentFrequency.id,
    loanTerm.months,
    debouncedCashDown,
    debouncedTradeIn
  );

  if (triggerPriceBreakdownUpdate) {
    refetch();
  }

  const changePurchaseOptionsSection = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    section: PurchaseOptionsSection
  ) => {
    e.preventDefault();
    setPurchaseOptionSection(section);
  };

  const tradeInButtonText = pricingBreakdown?.hasPersonalTradeInValue
    ? t("vehicleDisplayPage:view_trade")
    : t("vehicleDisplayPage:add_trade_in");

  const tradeInDisplayFormattedValue = (tradeInValue: number) => {
    return formatWithSymbol(
      tradeInValue,
      formatCurrency(tradeInValue, locale, {
        showCents: tradeInValue > 0 ? "always" : "never",
      })
    );
  };

  const isRightPaymentEligible =
    (pricingBreakdown?.hasPersonalTradeInValue ||
      pricingBreakdown?.hasPersonalInterestRate) ??
    false;

  const showPreviousListingPrice =
    !!vehiclePricing?.previousListingPrice &&
    canShowPreviousPrice(
      vehiclePricing.listingPrice,
      vehiclePricing.previousListingPrice
    );

  return (
    <div className="w-full">
      <h2 className="text-primary-deep text-center text-2xl leading-8 lg:text-4xl lg:leading-[3rem] font-semibold">
        {t("vehicleDisplayPage:purchase_options")}
      </h2>

      <div className="flex justify-center flex-wrap mt-2 md:mt-5 gap-10 text-primary-deep font-semibold text-lg leading-6">
        <a
          className={classNames({
            "text-primary-bold underline underline-offset-4 decoration-4":
              purchaseOptionSection === "financing",
          })}
          href="#"
          onClick={(e) => {
            gtmPushData({
              event: "VDP",
              element: "Purchase Options",
              subElement: "Financing",
              inventory: formatVehicleString(vehicle),
              hasSpinUrl: vehicle?.carMediaSpinUrl ?? false,
            });
            changePurchaseOptionsSection(e, "financing");
          }}
        >
          {t("common:financing")}
        </a>
        <a
          className={classNames({
            "text-primary-bold underline underline-offset-4 decoration-4":
              purchaseOptionSection === "cash",
          })}
          href="#"
          onClick={(e) => {
            gtmPushData({
              event: "VDP",
              element: "Purchase Options",
              subElement: "Cash Purchase",
              inventory: formatVehicleString(vehicle),
              hasSpinUrl: vehicle?.carMediaSpinUrl ?? false,
            });

            changePurchaseOptionsSection(e, "cash");
          }}
        >
          {t("vehicleDisplayPage:cash_purchase")}
        </a>
      </div>

      <div className="flex gap-x-6 lg:gap-x-24 flex-col md:flex-row bg-primary-deep rounded-2xl p-4 md:py-6 lg:px-10 lg:pt-8 lg:pb-6 mt-6 md:mt-5">
        {purchaseOptionSection === "financing" && (
          <>
            <div className="basis-[45%]">
              <h5 className="text-white md:mt-3">
                <>
                  {isRightPaymentEligible && (
                    <Trans
                      t={t}
                      i18nKey="vehicleDisplayPage:calculate_your_right_payment"
                      components={{
                        1: <span className="text-primary-bright" />,
                      }}
                    />
                  )}
                  {!isRightPaymentEligible && (
                    <span>
                      {t("vehicleDisplayPage:calculate_your_payment")}
                    </span>
                  )}
                </>
              </h5>
              <div className="flex items-end gap-4 mt-3 md:mt-6">
                <div id="trade-in-estimate-input" className="flex-1">
                  <MoneyInput
                    id="tradeInEstimate"
                    label={t("vehicleDisplayPage:trade_in_estimate")}
                    labelTextStyle="light-gray"
                    locale={locale || ""}
                    value={tradeInAmount}
                    onChange={(e) => setTradeInAmount(e)}
                  />
                </div>
                <Button
                  id="view-trade-button"
                  fill="outline"
                  buttonStyle="dark"
                  className="flex-1 h-11 my-1"
                  spacing="fixed"
                  rightIcon={<ArrowRightIcon />}
                  onClick={() => {
                    gtmPushData({
                      event: "VDP",
                      element: "Financing",
                      subElement: pricingBreakdown?.hasPersonalTradeInValue
                        ? "View Trade"
                        : "Add-Trade",
                      inventory: formatVehicleString(vehicle),
                      hasSpinUrl: vehicle?.carMediaSpinUrl ?? false,
                    });
                    onAddTradeInClick();
                  }}
                >
                  {tradeInButtonText}
                </Button>
              </div>
              <div className="flex items-center gap-3 mt-3">
                <InfoCircleIcon className="text-primary-light w-8 h-8" />
                <p className="caption-1 text-sm text-gray-200">
                  {t("vehicleDisplayPage:trade_in_current_ride")}
                </p>
              </div>
              <div className="mt-5 md:mt-7">
                <MoneyInput
                  id="downPayment"
                  label={t("vehicleDisplayPage:cash_down_payment")}
                  labelTextStyle="light-gray"
                  locale={locale || ""}
                  value={cashDown}
                  onChange={(e) => setCashDown(e)}
                  onFocus={() => {
                    gtmPushData({
                      event: "VDP",
                      element: "Financing",
                      subElement: "Cash-Down-Payment",
                      inventory: formatVehicleString(vehicle),
                      hasSpinUrl: vehicle?.carMediaSpinUrl ?? false,
                    });
                  }}
                />
              </div>
              <div className="mt-5 md:mt-7">
                <Label
                  label={t("common:payment_frequency")}
                  textStyle="light-gray"
                />
                <div className="flex gap-x-4 mt-2">
                  <PaymentFrequencyOption
                    text={translate(PaymentFrequencies.monthly.name, locale)}
                    isSelected={
                      paymentFrequency.id === PaymentFrequencies.monthly.id
                    }
                    onClick={() => {
                      gtmPushData({
                        event: "VDP",
                        element: "Financing",
                        subElement: "Monthly",
                        inventory: formatVehicleString(vehicle),
                        hasSpinUrl: vehicle?.carMediaSpinUrl ?? false,
                      });
                      setPaymentFrequency(PaymentFrequencies.monthly);
                    }}
                  />
                  <PaymentFrequencyOption
                    text={translate(PaymentFrequencies.biWeekly.name, locale)}
                    isSelected={
                      paymentFrequency.id === PaymentFrequencies.biWeekly.id
                    }
                    onClick={() => {
                      gtmPushData({
                        event: "VDP",
                        element: "Financing",
                        subElement: "Biweekly",
                        inventory: formatVehicleString(vehicle),
                        hasSpinUrl: vehicle?.carMediaSpinUrl ?? false,
                      });
                      setPaymentFrequency(PaymentFrequencies.biWeekly);
                    }}
                  />
                </div>
              </div>
              <div className="mt-5 md:mt-7">
                <Listbox
                  items={loanTerms}
                  label={t("vehicleDisplayPage:loan_length")}
                  labelTextStyle="light-gray"
                  keyOption={(item) => item.id}
                  displayOption={(item) => (item as unknown as LoanTerm).name}
                  displayButton={(item) => (item as unknown as LoanTerm).name}
                  valueOption={(item) => item}
                  selectedItem={loanTerm}
                  onChange={(item) => {
                    gtmPushData({
                      event: "VDP",
                      element: "Financing",
                      subElement: "Loan-Length",
                      inventory: formatVehicleString(vehicle),
                      hasSpinUrl: vehicle?.carMediaSpinUrl ?? false,
                    });
                    setLoanTerm(item);
                  }}
                />
              </div>
              {pricingBreakdown?.customerName && (
                <div className="mt-5 md:mt-7 py-5 bg-white/[.1] rounded-xl text-center">
                  <p className="text-gray-50">
                    {t("vehicleDisplayPage:viewing_right_payment_for")}
                  </p>
                  <p className="text-primary-bright">
                    {pricingBreakdown?.customerName}
                  </p>
                </div>
              )}
            </div>
            <div className="basis-[55%]">
              <div className="flex gap-x-4 justify-between mt-7 md:mt-0">
                <h5 className="text-white w-44">
                  {isRightPaymentEligible && (
                    <Trans
                      t={t}
                      i18nKey="vehicleDisplayPage:your_estimated_right_payment"
                      components={{
                        1: <span className="text-primary-bright" />,
                      }}
                    />
                  )}
                  {!isRightPaymentEligible && (
                    <span>
                      {t("vehicleDisplayPage:your_estimated_financing_terms")}
                    </span>
                  )}
                </h5>
                <div className="pt-4 pb-2 px-5 bg-primary-light rounded-lg rounded-b-none">
                  <div className="text-2xl leading-6 text-center font-semibold text-primary-bold">
                    {`${formatCurrency(
                      pricingBreakdown?.paymentAmount || 0,
                      locale
                    )}*`}
                  </div>
                  <div className="text-sm text-center font-medium text-gray-600 tracking-wider uppercase">
                    {translate(paymentFrequency.name, locale)}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-4 p-4 bg-white rounded-2xl rounded-tr-none">
                <LineItemContainer
                  title={t("vehicleDisplayPage:terms_breakdown")}
                >
                  {showPreviousListingPrice &&
                    vehiclePricing.previousListingPrice && (
                      <PreviousListingPriceLineItem
                        name={t("vehicleDisplayPage:previous_price")}
                        value={formatCurrency(
                          vehiclePricing.previousListingPrice,
                          locale
                        )}
                      />
                    )}
                  <LineItem
                    name={t("vehicleDisplayPage:vehicle_price")}
                    value={formatCurrency(vehiclePricing.listingPrice, locale)}
                  />
                  <LineItem
                    id="interest-rate-line-item"
                    name={t("common:interest_rate")}
                    value={formatPercent(
                      pricingBreakdown?.interestRate ??
                        vehiclePricing.interestRate,
                      locale
                    )}
                  />
                  <LineItem
                    name={t("vehicleDisplayPage:vehicle_total_interest")}
                    value={formatCurrency(
                      pricingBreakdown?.interestPaid ??
                        vehiclePricing.interestPaid,
                      locale
                    )}
                  />
                  <LineItem
                    name={t("vehicleDisplayPage:loan_length")}
                    value={loanTerm.name}
                  />
                </LineItemContainer>

                <LineItemContainer title={t("common:trade_in")}>
                  <LineItem
                    id="trade-in-estimate-line-item"
                    name={
                      vehiclePricing?.tradeInDescription ??
                      t("vehicleDisplayPage:estimated_trade_in_value")
                    }
                    value={tradeInDisplayFormattedValue(tradeInAmount)}
                    boldValue={tradeInAmount > 0}
                  />
                  {pricingBreakdown?.hasPersonalTradeInValue && (
                    <LineItem
                      name={t("vehicleDisplayPage:amount_owed")}
                      value={formatCurrency(
                        pricingBreakdown?.tradeInAmountOwing ?? 0,
                        locale
                      )}
                    />
                  )}
                </LineItemContainer>

                <LineItemContainer
                  title={t("vehicleDisplayPage:tax_breakdown")}
                >
                  {pricingBreakdown?.taxes.map((tax) => (
                    <LineItem
                      key={tax.name}
                      name={`${tax.name} (${formatPercent(
                        tax.rate,
                        locale,
                        MAX_DECIMALS_TAX_BREAKDOWN
                      )})`}
                      value={formatCurrency(tax.amount, locale)}
                    />
                  ))}
                </LineItemContainer>

                <div className="flex justify-between items-baseline">
                  <h6 className="text-lg leading-6 font-semibold text-primary-deep">
                    {t("vehicleDisplayPage:cash_down_payment")}
                  </h6>
                  <div
                    className={classNames("text-base leading-6 pr-1", {
                      "text-primary-bold font-semibold": cashDown > 0,
                      "text-primary-deep font-medium": cashDown <= 0,
                    })}
                  >
                    {formatWithSymbol(
                      cashDown,
                      formatCurrency(cashDown, locale, {
                        showCents: cashDown > 0 ? "always" : "never",
                      })
                    )}
                  </div>
                </div>

                <div className="flex justify-between items-baseline">
                  <h6 className="text-lg leading-6 font-semibold text-primary-deep">
                    {t("common:amount_financed")}
                  </h6>
                  <div className="text-lg leading-6 text-primary-deep font-semibold">
                    {formatCurrency(
                      pricingBreakdown?.amountFinanced ?? 0,
                      locale
                    )}
                  </div>
                </div>

                <div className="mx-auto">
                  <Button
                    spacing="hug"
                    rightIcon={<ArrowRightIcon />}
                    onClick={() => onApplyForFinancingClick()}
                  >
                    {t("common:apply_for_financing")}
                  </Button>
                </div>
              </div>

              <p className="mt-4 caption-4 text-white">
                {t("vehicleDisplayPage:purchase_options_fine_print")}
              </p>
            </div>
          </>
        )}
        {purchaseOptionSection === "cash" && (
          <>
            <div className="basis-[45%]">
              <h5 className="text-white md:mt-3">
                {t("vehicleDisplayPage:purchase_options")}
              </h5>
              <div className="flex items-end gap-4 mt-3 md:mt-6">
                <div className="flex-1">
                  <MoneyInput
                    id="tradeInEstimate"
                    label={t("vehicleDisplayPage:trade_in_estimate")}
                    labelTextStyle="light-gray"
                    locale={locale || ""}
                    value={tradeInAmount}
                    onChange={(e) => setTradeInAmount(e)}
                  />
                </div>
                <Button
                  fill="outline"
                  buttonStyle="dark"
                  className="flex-1 h-11 my-1"
                  spacing="fixed"
                  rightIcon={<ArrowRightIcon />}
                  onClick={() => {
                    gtmPushData({
                      event: "VDP",
                      element: "Cash-Purchase",
                      subElement: "Add-Trade",
                      inventory: formatVehicleString(vehicle),
                      hasSpinUrl: vehicle?.carMediaSpinUrl ?? false,
                    });
                    onAddTradeInClick();
                  }}
                >
                  {tradeInButtonText}
                </Button>
              </div>
              <div className="flex items-center gap-3 mt-3">
                <InfoCircleIcon className="text-primary-light w-8 h-8" />
                <p className="caption-1 text-sm text-gray-200">
                  {t("vehicleDisplayPage:trade_in_current_ride")}
                </p>
              </div>
            </div>
            <div className="basis-[55%]">
              <div className="flex gap-x-4 justify-between mt-7 md:mt-0">
                <h5 className="text-white w-44">
                  {t("vehicleDisplayPage:estimated_cash_breakdown")}
                </h5>
                <div className="pt-4 pb-2 px-5 bg-primary-light rounded-lg rounded-b-none">
                  <div className="text-2xl leading-6 text-center font-semibold text-primary-deep">
                    {formatCurrency(pricingBreakdown?.cashPrice || 0, locale)}
                  </div>
                  <div className="text-sm text-center font-medium text-gray-600 tracking-wider uppercase">
                    {t("vehicleDisplayPage:taxes_includ")}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-4 p-4 bg-white rounded-2xl rounded-tr-none">
                <LineItemContainer
                  title={t("vehicleDisplayPage:sales_breakdown")}
                >
                  {showPreviousListingPrice &&
                    vehiclePricing.previousListingPrice && (
                      <PreviousListingPriceLineItem
                        name={t("vehicleDisplayPage:previous_price")}
                        value={formatCurrency(
                          vehiclePricing.previousListingPrice,
                          locale
                        )}
                      />
                    )}
                  <LineItem
                    name={t("vehicleDisplayPage:vehicle_price")}
                    value={formatCurrency(vehiclePricing.listingPrice, locale)}
                  />
                  {pricingBreakdown?.taxes.map((tax) => (
                    <LineItem
                      key={tax.name}
                      name={`${tax.name} (${formatPercent(tax.rate, locale)})`}
                      value={formatCurrency(tax.amount, locale)}
                    />
                  ))}
                </LineItemContainer>

                <LineItemContainer title={t("common:trade_in")}>
                  <LineItem
                    name={t("vehicleDisplayPage:estimated_trade_in_value")}
                    value={tradeInDisplayFormattedValue(tradeInAmount)}
                    boldValue={tradeInAmount > 0}
                  />
                  {pricingBreakdown?.hasPersonalTradeInValue && (
                    <LineItem
                      name={t("vehicleDisplayPage:amount_owed")}
                      value={formatCurrency(
                        pricingBreakdown?.tradeInAmountOwing ?? 0,
                        locale
                      )}
                    />
                  )}
                </LineItemContainer>

                <div className="flex justify-between items-baseline">
                  <h6 className="text-lg leading-6 font-semibold text-primary-deep">
                    {t("vehicleDisplayPage:vehicle_total_cost")}
                  </h6>
                  <div className="text-lg leading-6 text-primary-deep font-semibold">
                    {formatCurrency(pricingBreakdown?.cashPrice || 0, locale)}
                  </div>
                </div>

                <div className="mx-auto">
                  <Button
                    spacing="hug"
                    rightIcon={<ArrowRightIcon />}
                    onClick={() => onNextStepsCashDownClick()}
                  >
                    {t("vehicleDisplayPage:explore_next_steps")}
                  </Button>
                </div>
              </div>

              <p className="mt-4 caption-4 text-white">
                {t("vehicleDisplayPage:purchase_options_fine_print")}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PurchaseOptions;
