import { gtmPushData } from "@common/helpers/gtm";
import useEmblaCarousel from "embla-carousel-react";
import VehicleList from "@modules/vehicle/types/vehicleList";
import VehicleCard from "@modules/vehicle/components/VehicleCard";
import { ReactNode, useCallback, useEffect, useState } from "react";
import DotButton from "@common/components/DotButton";

type Props = {
  vehicles: VehicleList[];
  emblaCarouselStyle: "mobile" | "tablet" | "desktop";
  lastCardOverlay?: (vehicleCard: ReactNode) => ReactNode;
  eventTag: string;
  isRightPaymentEligible: boolean;
};

const VehicleCardSetCarousel = ({
  vehicles,
  emblaCarouselStyle,
  lastCardOverlay,
  eventTag,
  isRightPaymentEligible,
}: Props) => {
  const [viewportRef, embla] = useEmblaCarousel({
    align: "start",
    skipSnaps: false,
    slidesToScroll: emblaCarouselStyle === "tablet" ? 2 : 1,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", () => {
      gtmPushData({
        event: eventTag,
        element: "Image-Carousel",
        subElement: "Thumbnail-Select",
      });
      onSelect();
    });
  }, [embla, onSelect, eventTag]);

  const scrollTo = useCallback(
    (index: number) => embla && embla.scrollTo(index),
    [embla]
  );

  let emblaContainerStyle = "";
  let emblaSlideStyle = "";

  switch (emblaCarouselStyle) {
    case "mobile":
      emblaContainerStyle = "ml-5";
      emblaSlideStyle = "min-w-[90%]";
      break;
    case "tablet":
      emblaContainerStyle = "-ml-3";
      emblaSlideStyle = "min-w-[50%]";
      break;
    case "desktop":
      emblaContainerStyle = "ml-10";
      emblaSlideStyle = "min-w-[60%]";
      break;
    default:
      throw Error("Style not supported");
  }

  return (
    <div className="relative mx-auto py-5 px-2">
      <div className="embla-viewport" ref={viewportRef}>
        <div className={`embla-container ${emblaContainerStyle}`}>
          {vehicles.map((vehicle, index) => {
            return (
              <div
                className={`relative pl-3 ${emblaSlideStyle}`}
                key={vehicle.id}
              >
                <div className="relative overflow-hidden">
                  {!!lastCardOverlay &&
                  index === vehicles.length - 1 &&
                  vehicles.length > 1 ? (
                    lastCardOverlay(
                      <VehicleCard
                        isRightPaymentEligible={isRightPaymentEligible}
                        key={vehicle.id}
                        vehicle={vehicle}
                        index={index}
                        isImageCarouselActive={false}
                        eventTag={eventTag}
                      />
                    )
                  ) : (
                    <VehicleCard
                      isRightPaymentEligible={isRightPaymentEligible}
                      key={vehicle.id}
                      vehicle={vehicle}
                      index={index}
                      isImageCarouselActive={false}
                      eventTag={eventTag}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {vehicles.length > 1 && (
        <div className="flex justify-center gap-x-3 w-full py-4">
          {vehicles
            .slice(emblaCarouselStyle === "tablet" ? 2 : 0)
            .map((vehicle, index) => (
              <DotButton
                key={vehicle.id}
                selected={index === selectedIndex}
                onClick={() => scrollTo(index)}
                buttonColours="secondary"
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default VehicleCardSetCarousel;
