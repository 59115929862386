import api from "@modules/api/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { VehiclePricingBreakdown } from "../types/VehiclePricingBreakdown";
import vehicleQueryKeys from "../vehicleQueryKeys";

const getVehiclePricingBreakdown = async (
  stockNumber: string,
  paymentFrequencyId: number,
  term: number,
  cashDownAmount: number,
  tradeInValue: number | null
): Promise<VehiclePricingBreakdown> => {
  const params = new URLSearchParams();
  params.append("paymentFrequencyId", paymentFrequencyId.toString());
  params.append("term", term.toString());
  params.append("cashDownAmount", cashDownAmount.toString());
  if (tradeInValue != null) {
    params.append("tradeInValue", tradeInValue.toString());
  }

  return api.get<VehiclePricingBreakdown>(
    `api/vehicles/${stockNumber}/pricing-breakdown?${params.toString()}`
  );
};

export default function useVehiclePricingBreakdown(
  stockNumber: string,
  paymentFrequencyId: number,
  term: number,
  cashDownAmount: number,
  tradeInValue: number | null
) {
  const { data, isError, error, refetch } = useQuery({
    queryKey: vehicleQueryKeys.pricingBreakdownByStockNumber(
      stockNumber,
      paymentFrequencyId,
      term,
      cashDownAmount,
      tradeInValue
    ),
    queryFn: () =>
      getVehiclePricingBreakdown(
        stockNumber,
        paymentFrequencyId,
        term,
        cashDownAmount,
        tradeInValue
      ),
    enabled: stockNumber.length > 0,
    placeholderData: keepPreviousData,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  return {
    pricingBreakdown: data ?? null,
    isLoading: !error && !data,
    isError,
    refetch,
  };
}
